exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actividades-extracurriculares-campamentos-jsx": () => import("./../../../src/pages/actividades-extracurriculares/campamentos.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-campamentos-jsx" */),
  "component---src-pages-actividades-extracurriculares-electivas-jsx": () => import("./../../../src/pages/actividades-extracurriculares/electivas.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-electivas-jsx" */),
  "component---src-pages-actividades-extracurriculares-index-jsx": () => import("./../../../src/pages/actividades-extracurriculares/index.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-index-jsx" */),
  "component---src-pages-actividades-extracurriculares-jidon-tanaj-jsx": () => import("./../../../src/pages/actividades-extracurriculares/jidon-tanaj.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-jidon-tanaj-jsx" */),
  "component---src-pages-actividades-extracurriculares-shabaton-jsx": () => import("./../../../src/pages/actividades-extracurriculares/shabaton.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-shabaton-jsx" */),
  "component---src-pages-actividades-extracurriculares-shorashim-jsx": () => import("./../../../src/pages/actividades-extracurriculares/shorashim.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-shorashim-jsx" */),
  "component---src-pages-actividades-extracurriculares-viaje-a-israel-jsx": () => import("./../../../src/pages/actividades-extracurriculares/viaje-a-israel.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-viaje-a-israel-jsx" */),
  "component---src-pages-actividades-extracurriculares-viaje-a-polonia-jsx": () => import("./../../../src/pages/actividades-extracurriculares/viaje-a-polonia.jsx" /* webpackChunkName: "component---src-pages-actividades-extracurriculares-viaje-a-polonia-jsx" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-envio-exitoso-js": () => import("./../../../src/pages/envio-exitoso.js" /* webpackChunkName: "component---src-pages-envio-exitoso-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-perfil-del-egresado-jsx": () => import("./../../../src/pages/perfil-del-egresado.jsx" /* webpackChunkName: "component---src-pages-perfil-del-egresado-jsx" */),
  "component---src-pages-programas-educativos-js": () => import("./../../../src/pages/programas-educativos.js" /* webpackChunkName: "component---src-pages-programas-educativos-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-secciones-escolares-index-jsx": () => import("./../../../src/pages/secciones-escolares/index.jsx" /* webpackChunkName: "component---src-pages-secciones-escolares-index-jsx" */),
  "component---src-pages-secciones-escolares-kinder-js": () => import("./../../../src/pages/secciones-escolares/kinder.js" /* webpackChunkName: "component---src-pages-secciones-escolares-kinder-js" */),
  "component---src-pages-secciones-escolares-prepa-js": () => import("./../../../src/pages/secciones-escolares/prepa.js" /* webpackChunkName: "component---src-pages-secciones-escolares-prepa-js" */),
  "component---src-pages-secciones-escolares-primaria-js": () => import("./../../../src/pages/secciones-escolares/primaria.js" /* webpackChunkName: "component---src-pages-secciones-escolares-primaria-js" */),
  "component---src-pages-secciones-escolares-secundaria-js": () => import("./../../../src/pages/secciones-escolares/secundaria.js" /* webpackChunkName: "component---src-pages-secciones-escolares-secundaria-js" */),
  "component---src-pages-tora-js": () => import("./../../../src/pages/tora.js" /* webpackChunkName: "component---src-pages-tora-js" */)
}

